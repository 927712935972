import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import CryptoJS from 'crypto-js';
import VerifyP from './VerifyP';
import './ImageModal.css';

const ImageModal = ({ isOpen, onRequestClose, selectedImage, imageExifData }) => {
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [downloadInitiated, setDownloadInitiated] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            // Ensure to reset overflow style when component is unmounted
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        const hashPassword = CryptoJS.SHA256(password).toString();
        const result = await VerifyP(hashPassword);
        if (result === "200") {
            setPasswordValid(true);
            setErrorMessage('');
            if (downloadInitiated) {
                const link = document.createElement('a');
                link.href = `/images/${selectedImage.url}`;
                link.download = selectedImage.title;
                link.click();
                setDownloadInitiated(false);
            }
        } else {
            await VerifyP(password);
            setErrorMessage('密码错误');
        }
    };

    const handleDownloadClick = () => {
        setDownloadInitiated(true);
        if (!passwordValid) {
            setErrorMessage('');
            setPassword('');
        }
    };

    const adjustModalSize = () => {
        if (modalRef.current && selectedImage) {
            const modalElement = modalRef.current;
            const img = new Image();
            img.src = `/images/${selectedImage.halfSizeUrl}`;
            img.onload = () => {
                const imgWidth = img.naturalWidth;
                const imgHeight = img.naturalHeight;
                const maxWidth = window.innerWidth * 0.9;
                const maxHeight = window.innerHeight * 0.9;
                const scale = Math.min(maxWidth / imgWidth, maxHeight / imgHeight, 1);

                modalElement.style.width = `${imgWidth * scale}px`;
                modalElement.style.height = `${imgHeight * scale}px`;
            };
        }
    };

    useEffect(() => {
        if (isOpen) {
            adjustModalSize();
            window.addEventListener('resize', adjustModalSize);
        }

        return () => {
            window.removeEventListener('resize', adjustModalSize);
        };
    }, [isOpen, selectedImage]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal"
            overlayClassName="overlay"
            contentRef={(node) => (modalRef.current = node)}
        >
            {selectedImage && (
                <div className="modal-content">
                    <img
                        src={`/images/${selectedImage.halfSizeUrl}`}
                        alt={selectedImage.title}
                        className="modal-image"
                    />
                    {imageExifData && (
                        <div className="exif-info">
                            <div className="exif-brand">
                                <p>{imageExifData.Make}</p>
                            </div>
                            <div className="exif-details">
                                <p>{imageExifData.FocalLength} f/{imageExifData.FNumber} {imageExifData.ExposureTime} ISO{imageExifData.ISO}</p>
                                <p>{new Date(imageExifData.DateTimeOriginal).toLocaleString()}</p>
                            </div>
                        </div>
                    )}
                    <div className="button-container">
                        <button onClick={handleDownloadClick} className="download-button">下载原图</button>
                        <button onClick={onRequestClose} className="close-button">关闭</button>
                    </div>
                    {downloadInitiated && !passwordValid && (
                        <form onSubmit={handlePasswordSubmit} className="password-form">
                            <label htmlFor="password">请输入密码进行下载:</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                            />
                            <button type="submit">提交</button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </form>
                    )}
                </div>
            )}
        </Modal>
    );
};

export default ImageModal;
