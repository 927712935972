async function GetLogs(selectDate) {
    // 日志仅用于优化和增强网站稳定性，提高用户体验
    const url = 'https://photossite-hoheuuafas.cn-shanghai.fcapp.run/';
    const jsonData = {
        "functionName":"getLogsData",
        "selectDate":selectDate
    }

    try {
        // 使用fetch发送POST请求
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        });
        const data = await response.json();
        //console.log('Response data:', data);
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

module.exports = GetLogs;