import React from 'react';

const Filters = ({ filter, uniqueFolders, onFilterChange, dateRange, onDateRangeChange, settings}) => (
    <div className="filters">
        <label htmlFor="folder-filter">{settings.pageTitles.filterLabel}</label>
        <select id="folder-filter" value={filter} onChange={onFilterChange}>
            <option value="all">所有</option>
            {uniqueFolders.map(folder => (
                <option key={folder} value={folder}>{folder}</option>
            ))}
        </select>
        <label htmlFor="start-date">{settings.pageTitles.startDateLabel}</label>
        <input
            type="date"
            id="start-date"
            value={dateRange.startDate}
            onChange={(e) => onDateRangeChange({ ...dateRange, startDate: e.target.value })}
        />
        <label htmlFor="end-date">{settings.pageTitles.endDateLabel}</label>
        <input
            type="date"
            id="end-date"
            value={dateRange.endDate}
            onChange={(e) => onDateRangeChange({ ...dateRange, endDate: e.target.value })}
        />
    </div>
);

export default Filters;
