const fetch = require('node-fetch');

async function SaveLogs(logData) {
    const url = 'https://photossite-hoheuuafas.cn-shanghai.fcapp.run/';

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(logData)
        });
        const data = await response.json();
        //console.log('Response data back:', data);
    } catch (error) {
        console.error('Error:', error);
    }
}

module.exports = SaveLogs;
