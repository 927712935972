async function VerifyP(password) {
    // 定义函数的URL和要发送的JSON对象
    const url = 'https://photossite-hoheuuafas.cn-shanghai.fcapp.run/';
    const jsonToSend = {
        'functionName': 'VerifyP',
        'password': password
    };

    try {
        // 使用fetch发送POST请求
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonToSend)
        });
        const data = await response.json();
        //console.log('Response data:', data);
        return data.code;
    } catch (error) {
        //console.error('Error:', error);
        return '400'; // 或者返回一个错误信息
    }
}

export default VerifyP;
