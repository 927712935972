import React, { useState, useEffect } from 'react';
import GetLogs from './GetLogs';
import './Details.css';

const Details = () => {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // 添加当前页码状态
    const [selectedDate, setSelectedDate] = useState(getCurrentYearMonth());
    const [loading, setLoading] = useState(true); // 添加加载状态
    const [error, setError] = useState(null); // 添加错误状态
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 检测是否为移动设备

    const logsPerPage = 6; // 每页显示6条日志

    useEffect(() => {
        console.log('Fetching logs for date:', selectedDate); // 调试信息
        fetchLogs(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchLogs = async (date) => {
        setLoading(true); // 开始加载
        setError(null); // 重置错误状态
        const logsData = await GetLogs(date);
        console.log('Logs data:', logsData); // 调试信息
        if (logsData && Array.isArray(logsData)) {
            // 对日志数据按照日期和时间进行排序
            logsData.sort((a, b) => {
                const dateA = new Date(`${a.currentDate} ${a.currentTime}`);
                const dateB = new Date(`${b.currentDate} ${b.currentTime}`);
                return dateB - dateA; // 降序排列，最新的日志在前
            });
            setLogs(logsData);
        } else {
            setLogs([]); // 如果没有日志数据，设置为空数组
        }
        setLoading(false); // 加载完成
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value.replace('-', ''));
    };

    // 计算当前页需要显示的日志
    const indexOfLastLog = currentPage * logsPerPage;
    const indexOfFirstLog = indexOfLastLog - logsPerPage;
    const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);

    const totalPages = Math.ceil(logs.length / logsPerPage); // 计算总页数

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="details">
            <h2>用户访问日志</h2>
            <div className="filter-container">
                <label htmlFor="date-select">选择年月：</label>
                <input
                    type="month"
                    id="date-select"
                    value={`${selectedDate.substring(0, 4)}-${selectedDate.substring(4)}`}
                    onChange={handleDateChange}
                />
            </div>
            {loading ? (
                <p>加载中...</p>
            ) : error ? (
                <p>加载日志时出错：{error}</p>
            ) : (
                <>
                    <p>当前页面一共有 {logs.length} 条访问日志。</p>
                    {logs.length === 0 ? (
                        <p>没有日志数据。</p>
                    ) : isMobile ? (
                        currentLogs.map((log, index) => (
                            <div key={index} className="log-card">
                                <div className="log-card-header">IP: {log.query}</div>
                                <div className="log-card-body">
                                    <p><strong>日期:</strong> {log.currentDate}</p>
                                    <p><strong>时间:</strong> {log.currentTime}</p>
                                    <p><strong>区域:</strong> {log.regionName}</p>
                                    <p><strong>经度:</strong> {log.lon}</p>
                                    <p><strong>纬度:</strong> {log.lat}</p>
                                    <p><strong>网络服务提供商:</strong> {log.as}</p>
                                    <p><strong>系统信息:</strong> {log.system.name} {log.system.version} ({log.system.arch} bit)</p>
                                    <p>{log.system.localAgeng}</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <table>
                            <thead>
                            <tr>
                                <th>IP</th>
                                <th>日期</th>
                                <th>时间</th>
                                <th>区域</th>
                                <th>经度</th>
                                <th>纬度</th>
                                <th>网络服务提供商</th>
                                <th>系统信息</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentLogs.map((log, index) => (
                                <tr key={index}>
                                    <td data-label="IP">{log.query}</td>
                                    <td data-label="日期">{log.currentDate}</td>
                                    <td data-label="时间">{log.currentTime}</td>
                                    <td data-label="区域">{log.regionName}</td>
                                    <td data-label="经度">{log.lon}</td>
                                    <td data-label="纬度">{log.lat}</td>
                                    <td data-label="网络服务提供商">{log.as}</td>
                                    <td data-label="系统信息">
                                        {log.system.name} {log.system.version} ({log.system.arch} bit)<br />
                                        {log.system.localAgeng}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

// 获取当前年月，格式为 'YYYYMM'
const getCurrentYearMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    return `${year}${month}`;
};

export default Details;
