import React, { useState, useEffect, useReducer } from 'react';
import LazyLoad from 'react-lazyload';
import Pagination from './Pagination';
import Filters from './Filters';
import ImageModal from './ImageModal';
import './Portfolio.css';
import './Filters.css';

const initialState = {
    settings: null,
    images: [],
    currentPage: 1,
    filteredImages: [],
    filter: 'all',
    dateRange: { startDate: '', endDate: '' },
    modalIsOpen: false,
    selectedImage: null,
    imageExifData: null,
    sortOption: 'default',
    showFilters: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return { ...state, settings: action.payload };
        case 'SET_IMAGES':
            return { ...state, images: action.payload, filteredImages: action.payload };
        case 'SET_CURRENT_PAGE':
            return { ...state, currentPage: action.payload };
        case 'SET_FILTER':
            return { ...state, filter: action.payload };
        case 'SET_DATE_RANGE':
            return { ...state, dateRange: action.payload };
        case 'SET_MODAL_IS_OPEN':
            return { ...state, modalIsOpen: action.payload };
        case 'SET_SELECTED_IMAGE':
            return { ...state, selectedImage: action.payload };
        case 'SET_IMAGE_EXIF_DATA':
            return { ...state, imageExifData: action.payload };
        case 'SET_SORT_OPTION':
            return { ...state, sortOption: action.payload };
        case 'SET_SHOW_FILTERS':
            return { ...state, showFilters: action.payload };
        case 'SET_FILTERED_IMAGES':
            return { ...state, filteredImages: action.payload };
        default:
            return state;
    }
};

const Portfolio = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        fetch('/settings.json')
            .then(response => response.json())
            .then(data => dispatch({ type: 'SET_SETTINGS', payload: data }))
            .catch(error => console.error('Error loading settings:', error));
    }, []);

    useEffect(() => {
        fetch('/images/images.json')
            .then(response => response.json())
            .then(data => dispatch({ type: 'SET_IMAGES', payload: data.images }))
            .catch(error => console.error('Error loading images:', error));
    }, []);

    useEffect(() => {
        applyFiltersAndSort();
    }, [state.filter, state.dateRange, state.images, state.sortOption]);

    const applyFiltersAndSort = () => {
        let filtered = state.images;

        if (state.filter !== 'all') {
            filtered = filtered.filter(image => image.folderName === state.filter);
        }

        if (state.dateRange.startDate) {
            filtered = filtered.filter(image => new Date(image.exifData.DateTimeOriginal) >= new Date(state.dateRange.startDate));
        }
        if (state.dateRange.endDate) {
            filtered = filtered.filter(image => new Date(image.exifData.DateTimeOriginal) <= new Date(state.dateRange.endDate));
        }

        if (state.sortOption === 'default') {
            filtered.sort((a, b) => {
                const aIsLandscape = a.thumbnailWidth >= a.thumbnailHeight;
                const bIsLandscape = b.thumbnailWidth >= b.thumbnailHeight;

                if (aIsLandscape !== bIsLandscape) {
                    return aIsLandscape ? -1 : 1;
                }

                return new Date(b.exifData.DateTimeOriginal) - new Date(a.exifData.DateTimeOriginal);
            });
        } else if (state.sortOption === 'dateAsc') {
            filtered.sort((a, b) => new Date(a.exifData.DateTimeOriginal) - new Date(b.exifData.DateTimeOriginal));
        } else if (state.sortOption === 'dateDesc') {
            filtered.sort((a, b) => new Date(b.exifData.DateTimeOriginal) - new Date(a.exifData.DateTimeOriginal));
        }

        dispatch({ type: 'SET_FILTERED_IMAGES', payload: filtered });
        dispatch({ type: 'SET_CURRENT_PAGE', payload: state.currentPage }); // 保持当前页
    };

    const handlePageChange = (pageNumber) => {
        dispatch({ type: 'SET_CURRENT_PAGE', payload: pageNumber });
    };

    const handleFilterChange = (event) => {
        dispatch({ type: 'SET_FILTER', payload: event.target.value });
        dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 }); // Reset to first page on filter change
    };

    const handleDateRangeChange = (range) => {
        dispatch({ type: 'SET_DATE_RANGE', payload: range });
        dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 }); // Reset to first page on date range change
    };

    const handleSortOptionChange = (event) => {
        dispatch({ type: 'SET_SORT_OPTION', payload: event.target.value });
        dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 }); // Reset to first page on sort change
    };

    const openModal = (image) => {
        dispatch({ type: 'SET_SELECTED_IMAGE', payload: image });
        dispatch({ type: 'SET_MODAL_IS_OPEN', payload: true });
        document.body.classList.add('modal-open');

        dispatch({ type: 'SET_IMAGE_EXIF_DATA', payload: image.exifData });
    };

    const closeModal = () => {
        dispatch({ type: 'SET_SELECTED_IMAGE', payload: null });
        dispatch({ type: 'SET_IMAGE_EXIF_DATA', payload: null });
        dispatch({ type: 'SET_MODAL_IS_OPEN', payload: false });
        document.body.classList.remove('modal-open');
        applyFiltersAndSort(); // Reapply filters and sort to ensure consistent state
    };

    const toggleFilters = () => {
        dispatch({ type: 'SET_SHOW_FILTERS', payload: !state.showFilters });
    };

    if (!state.settings) {
        return <div>Loading...</div>;
    }

    const imagesPerPage = state.settings.imagesPerPage;
    const displayedImages = state.filteredImages.slice((state.currentPage - 1) * imagesPerPage, state.currentPage * imagesPerPage);
    const uniqueFolders = [...new Set(state.images.map(image => image.folderName))];
    const totalPages = Math.ceil(state.filteredImages.length / imagesPerPage);

    const imageBorderStyle = {
        borderWidth: state.settings.imageBorder.width,
        borderColor: state.settings.imageBorder.color,
        borderStyle: state.settings.imageBorder.style
    };

    return (
        <div className="portfolio">
            <h2>{state.settings.pageTitles.portfolioTitle}</h2>
            <button onClick={toggleFilters} className="floating-button">
                筛选
            </button>
            {state.showFilters && (
                <div className="filters-container">
                    <Filters
                        filter={state.filter}
                        uniqueFolders={uniqueFolders}
                        onFilterChange={handleFilterChange}
                        dateRange={state.dateRange}
                        onDateRangeChange={handleDateRangeChange}
                        settings={state.settings}
                    />
                    <div className="sort-options">
                        <label htmlFor="sort-option">{state.settings.pageTitles.sortLabel}</label>
                        <select id="sort-option" value={state.sortOption} onChange={handleSortOptionChange}>
                            <option value="default">{state.settings.pageTitles.defaultSort}</option>
                            <option value="dateAsc">{state.settings.pageTitles.dateAscSort}</option>
                            <option value="dateDesc">{state.settings.pageTitles.dateDescSort}</option>
                        </select>
                    </div>
                </div>
            )}
            <div className="grid">
                {displayedImages.map((image, index) => (
                    <LazyLoad key={index} height={200} offset={100}>
                        <img
                            src={`/images/${image.thumbnailUrl}`}
                            alt={image.title}
                            onClick={() => openModal(image)}
                            className="thumbnail"
                            style={imageBorderStyle}
                        />
                    </LazyLoad>
                ))}
            </div>
            <Pagination totalPages={totalPages} currentPage={state.currentPage} onPageChange={handlePageChange} />
            {state.selectedImage && (
                <ImageModal
                    isOpen={state.modalIsOpen}
                    onRequestClose={closeModal}
                    selectedImage={state.selectedImage}
                    imageExifData={state.imageExifData}
                    settings={state.settings}
                />
            )}
        </div>
    );
};

export default Portfolio;
