import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // 修改这里
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import GetLocalDetails from './components/GetLocalDetails';
import Details from './components/Details';
import './App.css';

const App = () => {
    const footerRef = useRef(null);
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        GetLocalDetails().then(r => 0);
    }, []);

    useEffect(() => {
        fetch('/settings.json')
            .then(response => response.json())
            .then(data => setSettings(data))
            .catch(error => console.error('Error loading settings:', error));
    }, []);

    useEffect(() => {
        const adjustContentHeight = () => {
            if (footerRef.current) {
                const footerHeight = footerRef.current.offsetHeight;
                const contentElement = document.querySelector('.content');
                contentElement.style.minHeight = `calc(100vh - ${footerHeight}px)`;
            }
        };

        // Initial adjustment
        adjustContentHeight();

        // Adjust on window resize
        window.addEventListener('resize', adjustContentHeight);

        return () => {
            window.removeEventListener('resize', adjustContentHeight);
        };
    }, []);

    if (!settings) {
        return <div>Loading...</div>;
    }

    const backgroundStyle = {
        backgroundImage: `url(${settings.backgroundImage})`,
        filter: `blur(${settings.backgroundBlur}px) brightness(${settings.backgroundBrightness})`
    };

    const imageBorderStyle = {
        borderWidth: settings.imageBorder.width,
        borderColor: settings.imageBorder.color,
        borderStyle: settings.imageBorder.style
    };

    return (
        <Router>
            <div className="App">
                <div className="background" style={backgroundStyle}></div>
                <div className="content">
                    <main>
                        <Routes> {/* 修改这里 */}
                            <Route exact path="/" element={<Portfolio imageBorderStyle={imageBorderStyle} />} />
                            <Route path="/shadowdetails" element={<Details />} />
                        </Routes>
                    </main>
                </div>
                <Footer ref={footerRef} />
            </div>
        </Router>
    );
};

export default App;
