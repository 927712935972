// src/components/Footer.js
import React, { useEffect, useState, forwardRef } from 'react';
import './Footer.css';

const Footer = forwardRef((props, ref) => {
    const [footerInfo, setFooterInfo] = useState(null);

    useEffect(() => {
        fetch('/settings.json')
            .then(response => response.json())
            .then(data => setFooterInfo(data.footerInfo));
    }, []);

    if (!footerInfo) {
        return null;
    }

    return (
        <footer className="footer" ref={ref}>
            <p>{footerInfo.icpNumber}</p>
            <p>{footerInfo.copyright}</p>
            <p>{footerInfo.contactInfo}</p>
        </footer>
    );
});

export default Footer;
