const fetch = require('node-fetch');
const platform = require('platform');
const SaveLogs = require('./SaveLogs');

async function GetLocalDetails() {
    console.log("开始执行Details");
    //const url = `http://ip-api.com/json/`;
    const url = `https://ipapi.co/json/`;
    const now = new Date();
    const currentDate = now.toISOString().split('T')[0];
    const currentTime = now.toTimeString().split(' ')[0];

    let userAgent = 'unknown';
    try {
        userAgent = navigator.userAgent;
    }catch (error){
        userAgent = 'unknown';
    }

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        //console.log('Response data:', data);
        const selectDates = {
            "status": data.network,
            "query": data.ip,
            "currentDate": currentDate,
            "currentTime": currentTime,
            "regionName": data.city,
            "lat": data.latitude,
            "lon": data.longitude,
            "as": data.version,
            "system":{
                "name": platform.os.family,
                "version": platform.os.version,
                "arch": platform.os.architecture,
                "localAgeng": userAgent
            }
        }

        // 将获取到的数据作为参数传递给 SaveLogs 函数
        await SaveLogs(selectDates);
    } catch (error) {
        console.error('Error:', error);
    }
}

module.exports = GetLocalDetails;
