// src/components/Pagination.js
import React from 'react';
import './Pagination.css'; // 新建一个样式文件来处理分页按钮的样式

const Pagination = ({ totalPages, currentPage, onPageChange }) => (
    <div className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
            <button
                key={index}
                onClick={() => onPageChange(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
            >
                {index + 1}
            </button>
        ))}
    </div>
);

export default Pagination;
